
import { Component, Prop, Watch, Mixins, Vue } from 'vue-property-decorator';
@Component({
  components: {
    passwd: () => import('./components/password'),
    sms: () => import('./components/sms'),
    wechat: () => import('./components/wechat'),
    dingtalk: () => import('./components/dingtalk'),
  },
})
export default class PcMobile extends Vue {
  get currentTabComponent() {
   
    let s = (this as any).channels;
    return s;
  }
}
